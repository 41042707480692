<template>
  <Modal
    :activeModal="activeModal"
    @closeModal="closeModal">
    <template v-slot:btnActivator>
      <v-btn 
        tile 
        :color="variables.colorPrimary" 
        dark 
        class="mb-2 modal-cadastro-btn-cadastrar" 
        @click="openModal" 
        v-if="permiteAcao($route, 'add')"
        :loading="loadingBtnCadastro">Novo cadastro</v-btn>
    </template>
    <v-card>

      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        {{ isEdit ? `Editar Concurso: ${item.cod}` : `Cadastrar Concurso: ${item.cod}` }}
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <div class="abas mb-0"  width="100%">
        <v-tabs
          v-model="tab"
          background-color="#FFF"
          height="30px">
          <v-tab href="#tab-1" class="abas-item">
            Concurso
          </v-tab>

          <v-tab href="#tab-2" class="abas-item" :disabled="$v.item.$invalid">Prêmios</v-tab>
          <!-- <v-tab href="#tab-3" class="abas-item" :disabled="$v.item.$invalid">Feriados</v-tab> -->
          <!-- <v-tab href="#tab-3" class="abas-item">Feriados</v-tab> -->
        </v-tabs>
      </div>
      
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'">
          <v-form>
            <v-card-text class="modal-cadastro-form">
              <v-row>
                <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="visibleQtdCupons || isBingo ? '3': '3'">
                  <v-select :disabled="isEdit" :error-messages="ascertainedMethodIdErrors" :items="listaItemSelectMethods" label="Modalidades" v-model="item.ascertainedMethodId" @input="$v.item.ascertainedMethodId.$touch()" @blur="$v.item.ascertainedMethodId.$touch()"></v-select>
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" :sm="visibleQtdCupons || isBingo ? '3': '3'">
                  <v-text-field :error-messages="codErrors" v-model="item.cod" label="COD" @input="$v.item.cod.$touch()" @blur="$v.item.cod.$touch()" />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                  <dateTimerPicker
                    label="Data Concurso"
                    v-model="item.dtStart"
                    :datePickerProps="{locale: $store.getters.Locale}"
                    :timePickerProps="{format: '24hr'}"
                    date-format="dd/MM/yyyy"
                    :datetime="datetime"
                    :textFieldProps="{errorMessages: dtStartErrors}"
                    :key='componentKey1'
                    clearText="Limpar">
                  </dateTimerPicker>
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                   <v-text-field :error-messages="sweepstakeQtyError" type="number" v-model.number="item.sweepstakeQty" label="Qtd de dias Concursos"  @input="$v.item.sweepstakeQty.$touch()" @blur="$v.item.sweepstakeQty.$touch()" />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                   <v-text-field type="number" v-model.number="item.intervalExtraConfig.maxSweepstakesPerDay" label="Qtd Concursos por dia" />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="visibleQtdCupons">
                  <!-- <v-text-field type="number" :error-messages="sizeLotError" v-model="item.config.sizeLot" label="Quantidade de cupons" @input="$v.item.config.sizeLot.$touch()" @blur="$v.item.config.sizeLot.$touch()" /> -->
                  <vmoney 
                    type="number"
                    v-model="item.config.sizeLot"
                    label="Quantidade de cupons" 
                    :options="config"
                    :error="sizeLotError"
                    @input="$v.item.config.sizeLot.$touch()"
                    @blur="$v.item.config.sizeLot.$touch()"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-currency-field
                    v-model="item.config.cardConfig.expectedSales"
                    label="Previsão de vendas"
                    prefix="R$"
                    :error-messages="prevSalesError"
                    @input="$v.item.config.cardConfig.expectedSales.$touch()"
                    @blur="$v.item.config.cardConfig.expectedSales.$touch()"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-currency-field
                    v-model="item.config.cardConfig.payoutPercentage"
                    label="Payout"
                    suffix="%"
                    :error-messages="payoutError"
                    @input="$v.item.config.cardConfig.payoutPercentage.$touch()"
                    @blur="$v.item.config.cardConfig.payoutPercentage.$touch()"
                  />
                </v-col>


                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4" v-if="isBingo">
                  <v-text-field type="number" :error-messages="timerError" v-model="item.config.timer" label="Tempo em minutos" @input="$v.item.config.timer.$touch()" @blur="$v.item.config.timer.$touch()" />
                </v-col> -->

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-autocomplete
                    v-model="item.config.cardConfig.numCols"
                    :items="[3, 4, 5]"
                    type="tel"
                    label="Qtd. Colunas"
                    disabled
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-autocomplete
                    v-model="item.config.cardConfig.numLines"
                    :items="[3, 4, 5]"
                    type="tel"
                    label="Qtd. Linhas"
                    disabled
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="isBingo">
                  <v-autocomplete
                    v-model="item.config.cardConfig.maxNumber"
                    label="Max. Bolas"
                    :items="[90]"
                    :error="maxNumberError"
                    @input="$v.item.config.cardConfig.maxNumber.$touch()"
                    @blur="$v.item.config.cardConfig.maxNumber.$touch()"
                    disabled
                  />
                </v-col>
                

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="isBingo">
                  <v-text-field 
                    type="number"
                    v-model="item.config.accumulation.maxBalls"
                    label="Max. Bola Acomulado"
                  />
                </v-col>
                
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="isBingo">
                  <v-currency-field  
                    type="number"
                    v-model="item.config.accumulation.initialValue"
                    label="Valor Inicial"
                    prefix="R$"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="isBingo">
                  <v-currency-field  
                    type="number"
                    v-model="item.config.accumulation.percentage"
                    label="% Acumulado"
                    suffix="%"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
                  <v-text-field
                    v-model="item.startInterval"
                    type="tel"
                    label="Intervalo de início"
                    prepend-icon="access_time"
                    :error-messages="startIntervalErrors"
                  />
                </v-col>
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
                  <v-text-field
                    v-model="item.durationInterval"
                    type="tel"
                    label="Intervalo de fechamento"
                    prepend-icon="access_time"
                    :error-messages="durationIntervalErrors"
                  />
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3">
                  <v-text-field
                    v-model="item.drawInterval"
                    type="tel"
                    label="Início do sorteio após fechamento"
                    prepend-icon="access_time"
                    :error-messages="drawIntervalErrors"
                  />
                </v-col>

                <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4" v-if="!isPrognostico">
                  <dateTimerPicker
                    label="Data do fim do concurso"
                    v-model="item.config.dtEnd"
                    :datePickerProps="{locale: $store.getters.Locale, min: dataMinima(item.dtStart)}"
                    :timePickerProps="{format: '24hr', min: horaMinimaStart}"
                    date-format="dd/MM/yyyy"
                    time-format="HH:mm"
                    :datetime="datetime"
                    :textFieldProps="{errorMessages: dtEndErrors}"
                    :key='componentKey2'
                    clearText="Limpar"
                    @valorDate="compararDataEnd">
                  </dateTimerPicker>
                </v-col> -->

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
                  <v-select
                    v-model="item.weekDays"
                    :items="listWeekDays"
                    multiple
                    menu-props="auto"
                    label="Dias da semana">
                    <template v-slot:selection="{ item, index }">
                      <span v-if="index === 0">{{ item.text }}</span>
                      <span
                        v-if="index === 1"
                        class="grey--text caption ml-1"
                      > (+{{ listWeeksSelected.length - 1 }} outros)</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4" v-if="!isPrognostico">
                  <!-- <VCurrencyField v-model.number="item.config.price" :error="priceErrors" /> -->
                  <v-currency-field v-model="item.config.price" label="Preço do Cupom" prefix="R$" :error-messages="priceErrors"/>
                </v-col>
                
                <!-- upload de imagem do concurso -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4" v-if="!isPrognostico">
                  <v-file-input
                    accept="image/*"
                    label="Concurso"
                    prepend-icon="mdi-camera"
                    v-model="image"
                  ></v-file-input>
                </v-col> 

                <!-- upload de imagem do cupom -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="!isPrognostico">
                  <v-file-input
                    accept="image/*"
                    label="Frente do Cupom"
                    prepend-icon="mdi-camera"
                    v-model="imageCupom"
                  ></v-file-input>
                </v-col> 

                <!-- upload de imagem do verso do cupom -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="3" v-if="!isPrognostico">
                  <v-file-input
                    accept="image/*"
                    label="Verso do Cupom"
                    prepend-icon="mdi-camera"
                    v-model="imageCupomVerso"
                  ></v-file-input>
                </v-col> 

                <!-- upload do regulamento do concurso -->
                <v-col cols="12" class="modal-cadastro-form-col-imput" sm="2" v-if="!isPrognostico">
                  <v-file-input
                    label="Regulamento"
                    prepend-icon="mdi-paperclip"
                    v-model="regulationSweepstakes"
                    accept="application/pdf"
                  ></v-file-input>
                </v-col> 

                <v-col cols="12" v-if="!isPrognostico" >
                  <v-row>
                    <v-col v-show="item.image" sm="3">
                      <div class="wrapper-image">
                        <v-img :src="item.image" aspect-ratio="2.5"></v-img>
                        <span class="faixa">Concurso</span>
                      </div>
                    </v-col>


                    <v-col v-show="item.imageCupom" sm="3">
                      <div class="wrapper-image">
                        <v-img :src="item.imageCupom" aspect-ratio="2.5"></v-img>
                        <span class="faixa">Cupom Frente</span>
                      </div>
                    </v-col>
  
                    <v-col v-if="item.imageCupomVerso" sm="3">
                      <div class="wrapper-image">
                        <v-img :src="item.imageCupomVerso" aspect-ratio="2.5"></v-img>
                        <span class="faixa">Cupom Verso</span>
                      </div>

                    </v-col>
                    <v-col v-show="item.regulationSweepstakes" sm="3">
                      <div class="wrapper-image">
                        <a :href="item.regulationSweepstakes">
                          <v-img src="@/assets/img/placeholder-pdf.jpg" aspect-ratio="2.5"></v-img>
                          <span class="faixa">Regulamento</span>
                        </a>
                      </div>
                    </v-col>

                    <v-container>
                      <v-row>
                        <v-checkbox
                          label="Número bônus"
                          v-model="item.config.cardConfig.centralNumberBonus"
                          class="mt-0 mb-0"
                        />
                        <v-checkbox
                          label="É Intradiário"
                          v-model="item.isIntraday"
                          class="ml-5 mt-0 mb-0"
                        />
                        <v-checkbox
                          label="É Instantâneo"
                          v-model="item.isInstantaneous"
                          class="ml-5 mt-0 mb-0"
                        />

                        <v-checkbox
                          label="Sorteio automático"
                          v-model="item.automaticDraw"
                          class="ml-5 mt-0 mb-0"
                        />
                      </v-row>
                    </v-container>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions class="modal-cadastro-footer">
              <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
              <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="cadastrarConcurso([])" class="br-btn" :loading="loading" v-if="isEdit">{{ isEdit ? 'Salvar' : 'Cadastras' }}</v-btn>
              <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="proximo" class="br-btn" :loading="loading" v-else>{{ isEdit ? 'Salvar' : 'Próximo' }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-tab-item>

        <v-tab-item
          :key="2"
          :value="'tab-2'">
          <prizes :sweepstakes="item" :valueKey="valueKey" :loadingCadastro="loadingCadastro" @proximo="cadastrarConcurso" @voltar="tab = 'tab-1'" />
        </v-tab-item>
        <!-- <v-tab-item
          :key="3"
          :value="'tab-3'">
          <holidays :sweepstakes="item" :valueKey="valueKey" :loadingCadastro="loadingCadastro" @cadastrar-concurso="cadastrarConcurso" @voltar="tab = 'tab-2'" />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, minValue, requiredIf } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
//
// eslint-disable-next-line no-unused-vars
import { cloneDeep, map, orderBy } from 'lodash'
// import VCurrencyField from '@/views/components/vCurrencyField'
// import uploadImage from './uploadImage'
// eslint-disable-next-line no-unused-vars
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import vmoney from '@/views/components/vMoney'

import validacao from './validacao'

const namespaceStore = 'sweepstakesRecurrent'

export default {
  name: 'ModalCadastroConcursoRecorrente',
  mixins: [validationMixin],
  components: {
    prizes: () => import('./prizes/index'),
    // holidays: () => import('./holidays/index'),
    dateTimerPicker: () => import('@/views/components/dateTimePicker'),
    Modal: () => import('@/views/components/modal'),
    vmoney
  },
  data: () => ({
    activeModal: false,
    loading: false,
    loadingCadastro: false,
    menu: false,
    datetime: new Date(),
    image: [],
    imageCupom: [],
    imageCupomVerso: [],
    regulationSweepstakes: [],
    componentKey1: 0,
    componentKey2: 1000,
    componentKey3: 100000,
    tab: 'tab-1',
    valueKey: 0,
    keyModal: 0,
    horaMinimaStart: '00:00',
    horaMinimaDraw: '00:00',
    menuDatePicker: false,
    config: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '',
      length: 100,
      precision: 0
    },
    configPorcentage: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '%',
      length: 100,
      precision: 0
    },
    loadingBtnCadastro: false
  }),
  
  watch: {
    'image' (val) {
      this.addImage(val)
    }, 

    'imageCupom' (val) {
      this.addCupons(val)
    },

    'imageCupomVerso' (val) {
      this.addCuponVerso(val)
    },

    'regulationSweepstakes' (val) {
      this.addRegulationSweepstakes(val)
    }
  },

  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('sweepstakesRecurrentPrizesConfig', ['listaItens']),
    ...mapGetters(namespaceStore, ['item']),
    ...mapGetters('sweepstakes', ['listaItemSelectMethods']),
    ...validacao,
    variables: () => variables,
    
    isBingo () {
      return this.item.ascertainedMethodId === 'BINGO'
    },
    isPrognostico () {
      return this.item.ascertainedMethodId === 'PROG'
    },

    isEdit () {
      return this.item.id ? !!1 : !!0
    },

    computedDateFormatted () {
      return this.formatDate(this.date)
    },

    visibleQtdCupons () {
      switch (this.item.ascertainedMethodId) {
        case 'RASPADINHA':
        case 'BAUF':
        case 'BAU':
          return true
        default:
          return false
      }
    },
    listWeekDays () {
      return [
        { text: 'Domingo', value: 1 },
        { text: 'Segunda-feira', value: 2 },
        { text: 'Terça-feira', value: 3 },
        { text: 'Quarta-feira', value: 4 },
        { text: 'Quinta-feira', value: 5 },
        { text: 'Sexta-feira', value: 6 },
        { text: 'Sábado', value: 7 }
      ]
    },
    listWeeksSelected () {
      return this.item.weekDays
    }
  },
  mounted () {
    this.getMethos()
    this.item.drawInterval = 0
    Events.$on('cadastro::openModalCadastro', () => {
      this.activeModal = true
      this.forceRerender()
    })
  },
  methods: {
    formatWeeks (arr) {
      return map(this.listWeekDays, v => arr.includes(v.value) ? 1 : 0).join('')
    },
    ...mapActions(namespaceStore, ['cadastrarItem', 'limparItem', 'getItens', 'editarItem', 'addHolidaySweepstakeRecurrence']),
    ...mapActions('sweepstakes', ['getMethos']),
    ...mapActions('prizes', {
      obterPremios: 'obterItens',
      limparItemPremios: 'limparItens'
    }),
    ...mapActions('prizeTypes', {
      getItensPrizeTypes: 'getItens'
    }),

    openModal () {
      this.activeModal = true
    },

    forceRerender() {
      this.componentKey1 += 1  
      this.componentKey2 += 1  
      this.componentKey3 += 1
      this.valueKey += 1
      this.keyModal += 1
    },

    closeModal () {
      this.forceRerender()
      this.image = null
      this.imageCupom = null
      this.imageCupomVerso = null
      this.regulationSweepstakes = null
      this.activeModal = false
      this.limparItem()
      this.limparItemPremios()
      this.tab = 'tab-1'
      this.$v.item.$reset()
      Events.$emit('modalCadastroSweepstakes::closeModal')
    },

    dataMinima (date = '01/01/1900') {
      return moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    compararDataEnd(payload) {
      if (moment(this.item.dtStart).isSame(moment(payload, 'YYYY-MM-DD'), 'day')) {
        this.horaMinimaStart = moment(this.item.dtStart, 'DD/MM/YYYY HH:mm').format('HH:mm')
        return
      } 

      this.horaMinimaStart = ''
    },

    compararDataDraw(payload) {
       if (moment(this.item.config.dtEnd).isSame(moment(payload, 'YYYY-MM-DD'), 'day')) {
        this.horaMinimaDraw = moment(this.item.config.dtEnd, 'DD/MM/YYYY HH:mm').format('HH:mm')
        return
      }

      this.horaMinimaDraw = ''
    },

    formatDate (date) {
      moment().locale(this.$store.getters.Locale)
      if (!date) return null
      return moment(date).format('DD/MM/YYYY')
    },

    addImage (e) {
      if (!e) {
        this.item.image = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.image = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.image = e.target.result;
      reader.readAsDataURL(e);
    },

    addCupons (e) {
      if (!e) {
        this.item.imageCupom = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.imageCupom = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.imageCupom = e.target.result;
      reader.readAsDataURL(e);
    },

    addCuponVerso (e) {
      if (!e) {
        this.item.imageCupomVerso = ''
        return
      }
      if (!e.type.match('image.*')) {
        this.item.imageCupomVerso = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.imageCupomVerso = e.target.result;
      reader.readAsDataURL(e);
    },

    addRegulationSweepstakes (e) {
      if (!e) {
        this.item.regulationSweepstakes = ''
        return
      }
      if (!e.type.match('application/pdf')) {
        this.item.regulationSweepstakes = ''
        return
      }
      // const img = new Image(),
      const reader = new FileReader();
      reader.onload = (e) => this.item.regulationSweepstakes = e.target.result;
      reader.readAsDataURL(e);
    },

    proximo () {
      if (this.$v.item.$invalid) { 
        this.$v.item.$touch()
        return false 
      }

      this.tab = 'tab-2'
    },

    cadastrarConcurso (val) {
      let self = this
      this.loading = true
      this.loadingCadastro = true

      // informações de configuração de concursos 
      this.item.config = {
        image: this.item.image,
        imageCupom: this.item.imageCupom,
        imageCupomVerso: this.item.imageCupomVerso,
        regulationSweepstakes: this.item.regulationSweepstakes ? this.item.regulationSweepstakes : '',
        price: this.item.config ? this.item.config.price : this.item.config,
        cardConfig: this.item.config ? this.item.config.cardConfig : this.item.config,
        accumulation: this.item.config ? this.item.config.accumulation : this.item.config,
        sizeLot: this.item.config ? this.item.config.sizeLot : this.item.config,
        timer: this.config.timer
      }
      const dados = {
        ascertainedMethodId: this.item.ascertainedMethodId,
        cod: this.item.cod,
        dtStart: moment(this.item.dtStart, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm'),
        sweepstakeConfig: this.item.config,
        sweepstakeQty: parseInt(this.item.sweepstakeQty),
        startInterval: this.item.startInterval,
        weekDays: this.formatWeeks(this.listWeeksSelected),
        durationInterval: this.item.durationInterval,
        drawInterval: this.item.drawInterval,
        automaticDraw: this.item.automaticDraw,
        isInstantaneous: this.item.isInstantaneous,
        isIntraday: this.item.isIntraday,
        intervalExtraConfig: this.item.intervalExtraConfig
      }

      if (this.item.id) {
        dados.id = parseInt(this.item.id)

        this.editarItem(dados).then(() => {
          this.closeModal()
          this.loading = false
          this.loadingCadastro = false
          if (val.length > 0) {
            // const data = {
            //     sweepstakeRecurrenceId: result.data.id,
            //     holidayId: val[0].id
            // }
            successSnackbar('Edição realizado com sucesso!')
            self.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
            // self.addHolidaySweepstakeRecurrence(data).then(() => {})
          }
        }).catch(err => {
          this.loading = false
          this.loadingCadastro = false
          errorSnackbar(err.error)
        })
      } else {
        dados.prizes = this.repetirPremios(dados, cloneDeep(this.listaItens))
        this.cadastrarItem(dados).then(result => {
          this.tab = 'tab-3'
          this.item.id = result.data.id
          this.loading = false
          this.loadingCadastro = false

          // cadastra feriados
          this.closeModal()
          successSnackbar('Cadastro realizado com sucesso!')
          self.getItens({ page: 1, pageSize: this.$store.getters.ItensPaginacao })
          // if (!val[0]) return successSnackbar('Cadastro realizado com sucesso!')
          // self.addHolidaySweepstakeRecurrence({sweepstakeRecurrenceId: result.data.id, holidayId: val[0].id}).then(() => {
          // }).catch(err => errorSnackbar(err.error))

        }).catch(err => {
          this.loading = false
          this.loadingCadastro = false
          errorSnackbar(err.error)
        })
      }
    },

    repetirPremios (concurso, listPremios) {
      const premios = []
      let idxPremio = 1

      const premiosOrdenados = orderBy(listPremios, ['number'])

      premiosOrdenados.forEach(item => {
        if (item.prizeTypeId === 'PRC')  {
          item.percentage = item.percentage / concurso.sweepstakeConfig.cardConfig.numCols
          item.value = item.value / concurso.sweepstakeConfig.cardConfig.numCols

          for (let i = 0 ; i < concurso.sweepstakeConfig.cardConfig.numCols ; i++) {
            this.addPremios(premios, item, idxPremio)
            idxPremio++
          }

          return
        }

        if (item.prizeTypeId === 'PRT')  {
          item.percentage = item.percentage / 2
          item.value = item.value / 2
          
          for (let i = 0 ; i < 2 ; i++) {
            this.addPremios(premios, item, idxPremio)
            idxPremio++
          }

          return
        }

        if (item.prizeTypeId === 'PRL')  {
          item.percentage = item.percentage / concurso.sweepstakeConfig.cardConfig.numLines
          item.value = item.value / concurso.sweepstakeConfig.cardConfig.numLines

          for (let i = 0 ; i < concurso.sweepstakeConfig.cardConfig.numLines ; i++) {
            this.addPremios(premios, item, idxPremio)
            idxPremio++
          }

          return
        }

        this.addPremios(premios, item, idxPremio)
        idxPremio++
      })

      return premios
    },

    addPremios(premios, premio, idxPremio) {
      const clonePremio = { ...premio }
      clonePremio.number = idxPremio
      premios.push(clonePremio)
    }
  },

  validations () {
    return  {
      item: {
        ascertainedMethodId: {required },
        cod: {required },
        startInterval: { required },
        // timer: { required: requiredIf(() => this.isBingo) },
        sweepstakeQty: { required },
        durationInterval: { required, minValue: minValue(1) },
        drawInterval: { required },
        dtStart: { required },
        config: {
          price: { required: requiredIf(() => !this.isPrognostico) },
          sizeLot: { required: requiredIf(() => this.visibleQtdCupons),  minValue: minValue(1) },
          cardConfig: {
            maxNumber: { required: requiredIf(() => this.isBingo) },
            expectedSales: {
              required: requiredIf(() => this.isBingo),
              minValue:  minValue(0.01)
            },
            payoutPercentage: { 
              required: requiredIf(() => this.isBingo),
              minValue:  minValue(0.01)
            },
          },
          accumulation: {
            // maxBalls: { required: requiredIf(() => this.isBingo) },
            // percentage: { required: requiredIf(() => this.isBingo) }
          }
          // dtEnd: {
            // required: requiredIf(() => !this.isPrognostico),
            // required,
            // invalidDate: paramsInvalidDateEnd(this.item.dtStart)
          // },
          // dtDraw: {
          //   // required: requiredIf(() => !this.isPrognostico),
          //   required,
          //   invalidDate: paramsInvalidDateDraw(this.item.config.dtEnd)
          // }
        }
      }
    }
  }
}
</script>

<style lang="scss" src="@/assets/styles/view/modal-cadastro.scss"></style>