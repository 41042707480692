// import moment from 'moment';

export default {
  sweepstakeIdErrors () {
    const errors = []
    if (!this.$v.item.sweepstakeId.$dirty) return errors
    !this.$v.item.sweepstakeId.required && errors.push('Concurso é obrigatório')
    return errors
  },
  startIntervalErrors () {
    const errors = []
    if (!this.$v.item.startInterval.$dirty) return errors
    !this.$v.item.startInterval.required && errors.push('Intervalo é obrigatório')
    return errors
  },
  ascertainedMethodIdErrors () {
    const errors = []
    if (!this.$v.item.ascertainedMethodId.$dirty) return errors
    !this.$v.item.ascertainedMethodId.required && errors.push('Modalidade é obrigatório')
    return errors
  },

  codErrors () {
    const errors = []
    if (!this.$v.item.cod.$dirty) return errors
    !this.$v.item.cod.required && errors.push('O COD é obrigatório.')
    return errors
  },

  dtStartErrors () {
    const errors = []
    if (this.$v.item.dtStart.$dirty) {
      !this.$v.item.dtStart.required && errors.push('Data de início é obrigatório.')
    }
    return errors
  },
  
  priceErrors () {
    const errors = []
    if (!this.$v.item.config.price.$dirty) return errors
    !this.$v.item.config.price.required && errors.push('Valor do cupom obrigatório.')
    !this.$v.item.config.price.minValue && errors.push('Valor do cupom obrigatório.')
    return errors
  },
  
  sizeLotError () {
    const errors = []
    if (!this.$v.item.config.sizeLot.$dirty) return errors
    !this.$v.item.config.sizeLot.required && errors.push('Tamanho do lote de cupons é obrigatório.')
    !this.$v.item.config.sizeLot.minValue && errors.push('O valor tem que ser maior que 0.')
    return errors
  },

  durationIntervalErrors () {
    const errors = []
    if (this.$v.item.durationInterval.$dirty) {
      !this.$v.item.durationInterval.required && errors.push('Duração do intervalo obrigatório.')
      !this.$v.item.durationInterval.minValue && errors.push('Obrigatorio duração entre inicio e fim.')
    }
    return errors
  },
  drawIntervalErrors () {
    const errors = []
    if (this.$v.item.drawInterval.$dirty) {
      !this.$v.item.drawInterval.required && errors.push('Duração do intervalo obrigatório.')
    }
    return errors
  },
  sweepstakeQtyError () {
    const errors = []
    if (!this.$v.item.sweepstakeQty.$dirty) return errors
    !this.$v.item.sweepstakeQty.required && errors.push('Obrigatório quantidade de concurso')
    return errors
  },
  maxBallsError () {
    const errors = []
    if (!this.$v.item.config.accumulation.maxBalls.$dirty) return errors
    !this.$v.item.config.accumulation.maxBalls.required && errors.push('Campo Obrigatório')
    return errors
  },
  percentageError () {
    const errors = []
    if (!this.$v.item.config.accumulation.percentage.$dirty) return errors
    !this.$v.item.config.accumulation.percentage.required && errors.push('Campo Obrigatório')
    return errors
  },
  maxNumberError () {
    const errors = []
    if (!this.$v.item.config.cardConfig.maxNumber.$dirty) return errors
    !this.$v.item.config.cardConfig.maxNumber.required && errors.push('Campo Obrigatório')
    return errors
  },
  // dtEndErrors () {
  //   const errors = []
  //   // if (this.isPrognostico) return errors
  //   if (this.$v.item.config.dtEnd.$dirty) {
  //     !this.$v.item.config.dtEnd.required && errors.push('Data de início é obrigatório.')
  //     !this.$v.item.config.dtEnd.invalidDate && errors.push('Data não pode ser menor ou igual a data de inicio!')
  //   }
  //   return errors
  // },
  // dtDrawErrors () {
  //   const errors = []
  //   // if (this.isPrognostico) return errors
  //   if (!this.$v.item.config.dtDraw.$dirty) return errors
  //   !this.$v.item.config.dtDraw.required && errors.push('Data de sorteio é obrigatório.')
  //   !this.$v.item.config.dtDraw.invalidDate && errors.push('Data não pode ser menor que a data de fim do concurso!')
  //   return errors
  // },

  prevSalesError () {
    const errors = []
    if (!this.$v.item.config.cardConfig.expectedSales.$dirty) return errors
    !this.$v.item.config.cardConfig.expectedSales.required && errors.push('Campo Obrigatório')
    !this.$v.item.config.cardConfig.expectedSales.minValue && errors.push('Campo Obrigatório')
    return errors
  },

  payoutError () {
    const errors = []
    if (!this.$v.item.config.cardConfig.payoutPercentage.$dirty) return errors
    !this.$v.item.config.cardConfig.payoutPercentage.required && errors.push('Campo Obrigatório')
    !this.$v.item.config.cardConfig.payoutPercentage.minValue && errors.push('Campo Obrigatório')
    return errors
  }
}
